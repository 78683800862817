import "./App.css";
import Main from "./components/MainComponet";
import React, { useEffect } from "react";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'id=GTM-5K876FMB'
}
TagManager.initialize(tagManagerArgs)

function App() {
  useEffect(() => { TagManager.initialize(tagManagerArgs); 
    if (window.dataLayer) { 
      window.dataLayer.push({ event: 'pageview' });
      console.log('Pageview event pushed to dataLayer');
     } }, []);
  return (
    <div className="App">
       <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5K876FMB"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      <Main />
    </div>
  );
}

export default App;
